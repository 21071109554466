import NavMenu from '../views/pages/Shared/NavMenu.vue';
import VRequestCreateModal from '../views/modals/VRequestCreateModal.vue';
import oidc from '../vue-oidc-client';

export default [
    //aliases for legacy routing
    {
        name: 'request.create.form',
        path: '/request/categories/services',
        components: {
            menu: NavMenu,
            VRequestCreateModal,
        },
        meta: {
            authName: oidc.authName,
        },
    },
];
