<template>
    <div class="filter-activity">
        <VueSelect
            class="v-select-light filter-activity__control"
            multiple
            placeholder="Пользователи"
            :options="options"
            v-model="users"
        ></VueSelect>

        <FlatPickr
            class="input input_light filter-activity__control"
            placeholder="Период активности"
            required
            :config="config"
            v-model="period"
        ></FlatPickr>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component
class FilterActivity extends Vue {
    users = [];
    period = '';
    config = {
        mode: 'range',
        weekNumbers: true,
        dateFormat: 'd.m.Y',
        locale: Russian,
    };
    @Workspace.State((state) =>
        state.accounts
            .filter((account) => account.enabled)
            .map((account) => ({
                value: account.id,
                label: account.displayName,
            })),
    )
    options;

    getValue() {
        if (!this.period) {
            return null;
        }

        let period = this.period.split(' — ', 2);

        if (period.length == 1) {
            period.push(null);
        }

        return this.users.length && period.length === 2 && this
            ? period.concat(this.users.map((option) => option.value))
            : false;
    }

    reset() {
        this.users = [];
        this.period = '';
    }
}

export default FilterActivity;
</script>
<style lang="scss">
.filter-activity {
    &__control {
        margin: 4px 0;
    }
}
</style>
