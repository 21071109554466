<template>
    <div>
        <div>
            <ValidationProvider class="active-form__field" name="observers" v-slot="{ errors }">
                <label class="active-form__label">Наблюдатели</label>
                <VSelect
                    :options="options"
                    class="active-form__control"
                    multiple
                    placeholder="Выберите наблюдателя"
                    :value="value"
                    @input="onInputValue"
                    id="request-observer"
                    name="observers"
                ></VSelect>

                <span class="active-form__error">
                    {{ errors[0] }}
                </span>
            </ValidationProvider>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';

import VRequestObserver from './VRequestObserver.vue';
import { ValidationProvider } from 'vee-validate';
import { namespace } from 'vuex-class';
import VSelect from '../VSelect/VSelect.vue';

const Workspace = namespace('workspace');

/**
 * @property {Array} value
 */
@Component({
    components: {
        VRequestObserver,
        VSelect,
        ValidationProvider,
    },
})
class VRequestObservers extends Vue {
    /** @type {Number[]} **/
    @Prop({ type: Array, default: () => [] }) value;

    /** @type {Account[]} **/
    @Workspace.State accounts;

    @Emit('input')
    onInputValue(value) {
        return value;
    }

    /** @type {{ value: Number, label: String }[]} **/
    get options() {
        return this.accounts
            .filter((account) => account.enabled)
            .map((account) => ({
                value: account.id,
                label: account.displayName,
            }));
    }
}

export default VRequestObservers;
</script>
