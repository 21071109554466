<template>
    <VueSelect
        :options="options"
        class="v-select-light"
        multiple
        placeholder="Группа ответственных"
        v-model="value"
    ></VueSelect>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component
class FilterSecurityGroup extends Vue {
    @Workspace.State((state) =>
        state.securityGroups.map((securityGroup) => ({
            value: securityGroup.id,
            label: securityGroup.name,
        })),
    )
    options;

    value = [];

    getValue() {
        return this.value.map((value) => value.value);
    }

    reset() {
        this.value = [];
    }
}

export default FilterSecurityGroup;
</script>
