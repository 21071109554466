<template>
    <div class="request-card">
        <div class="request-card__column request-card__column_left">
            <div class="request-card__row">
                <span class="request-card__row-key">Статус</span>

                <span class="request-card__row-value" :style="{ color: statusColor }">{{ statusTitle }}</span>
            </div>

            <div class="request-card__row">
                <span class="request-card__row-key">Автор</span>

                <div class="request-card__row-value request-card__row-value_author">
                    <UserCardWithPopover short-name :account="request.author"></UserCardWithPopover>
                </div>
            </div>

            <div
                class="request-card__row"
                :class="{
                    'request-card__row_acting': $_isExecutor,
                }"
                @click="changeExecutor"
            >
                <span class="request-card__row-key">Исполнитель</span>

                <div class="request-card__row-value request-card__row-value_executor">
                    <template v-if="request.executor">
                        <UserCardWithPopover short-name :account="request.executor"></UserCardWithPopover>
                    </template>

                    <template v-else-if="request.securityGroup">
                        <div class="request-executors" v-if="request.securityGroup.accounts" v-tooltip="executorNames">
                            <span class="request-executors__group-name">
                                {{ request.securityGroup.name }}
                            </span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="request-card__column request-card__column_separator"></div>

        <div class="request-card__column request-card__column_right">
            <div
                class="request-card__row"
                :class="{
                    'request-card__row_acting': $_isExecutor,
                }"
                @click="changeCategory"
            >
                <span class="request-card__row-key">Категория</span>

                <span class="request-card__row-value" v-tooltip="request.category.title">{{
                    request.category.title
                }}</span>
            </div>

            <div
                class="request-card__row"
                :class="{
                    'request-card__row_acting': $_isExecutor,
                }"
                @click="changeService"
            >
                <span class="request-card__row-key">Сервис</span>

                <span class="request-card__row-value" v-tooltip="request.service.title">{{
                    request.service.title
                }}</span>
            </div>

            <div class="request-card__row">
                <span class="request-card__row-key">Срок</span>

                <span
                    class="request-card__row-value"
                    :style="{ color: stateColor }"
                    v-tooltip="$_toLocalDateHistory(request.deadline)"
                >
                    <template v-if="isRequestPaused"> Приостановлено </template>
                    <template v-else-if="isRequestOverdue"> Просрочено </template>
                    <template v-else-if="isInWork">
                        {{ $_toLocalRelativeDate(request.deadline) }}
                    </template>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RequestStatusType from '@/js/enums/RequestStatusType';
import RequestStatusColor from '@/js/enums/RequestStatusColor';
import RequestStatusTitle from '@/js/enums/RequestStatusTitle';
import RequestStateType from '@/js/enums/RequestStateType';
import RequestStateColor from '@/js/enums/RequestStateColor';

import UserCard from '@/views/components/VUser/UserCard.vue';
import UserCardWithPopover from '@/views/components/VUser/UserCardWithPopover.vue';

@Component({
    components: {
        UserCard,
        UserCardWithPopover,
    },
})
class RequestViewCard extends Vue {
    @Prop({ type: Object, required: true })
    request;

    changeExecutor() {
        if (this.$_isExecutor) {
            this.$emit('changeExecutor');
        }
    }

    changeService() {
        if (this.$_isExecutor) {
            this.$emit('changeService');
        }
    }

    changeCategory() {
        if (this.$_isExecutor) {
            this.$emit('changeCategory');
        }
    }

    get executorNames() {
        if (this.request.executor) {
            return this.request.executor.displayName;
        }

        if (this.request.securityGroup) {
            if (this.request.securityGroup.accounts) {
                const names = this.request.securityGroup.accounts.map((account) => account.displayName);

                return 'Исполнители: \n' + names.join('\n');
            }

            return this.request.securityGroup.name;
        }

        return ':sad:';
    }

    get stateColor() {
        var state = RequestStateType.type(this.request);

        return RequestStateColor[state];
    }

    get statusColor() {
        return RequestStatusColor[this.request.status];
    }

    get statusTitle() {
        return RequestStatusTitle[this.request.status];
    }

    get isInWork() {
        return this.request.status === RequestStatusType.WORKUP;
    }

    get isRequestPaused() {
        return this.request.status === RequestStatusType.PAUSED;
    }

    get isRequestOverdue() {
        return RequestStateType.isFatal(this.request);
    }
}

export default RequestViewCard;
</script>

<style lang="scss">
:root {
    --request-card-key-color: #808080;
    --request-card-value-color: #484848;
    --request-card-border: #e4eaef;
    --request-card-background: #fbfbfd;
}

.request-card {
    display: flex;
    width: 100%;
    background: var(--request-card-background);
    border: 1px solid var(--request-card-border);
    border-radius: 12px;

    &__row {
        display: flex;
        width: 100%;
        height: 46px;
        border-bottom: 1px solid var(--request-card-border);
        justify-content: space-between;

        &:last-child {
            height: 46px;
            border-bottom: none;
        }

        &_acting {
            cursor: pointer;
        }
    }

    &__row-key {
        padding-right: 15px;
        line-height: 46px;
        font-size: 0.75rem;
        color: var(--request-card-key-color);

        @media (min-width: $lg-desktop-breakpoint) {
            font-size: 0.875rem;
        }
    }

    &__row-value {
        overflow: hidden;
        font-size: 0.75rem;
        text-align: end;
        line-height: 46px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--request-card-value-color);

        @media (min-width: $lg-desktop-breakpoint) {
            font-size: 0.875rem;
        }

        &_author,
        &_executor {
            display: flex;
            align-items: center;

            & .user-card {
                width: auto;
            }
        }
    }

    &__column {
        width: 50%;
        flex: 0 0 50%;
        margin: 4px 0;
        padding: 0 20px;

        &_left {
            padding-right: 28px;
        }

        &_right {
            padding-left: 28px;
        }

        &_separator {
            width: 1px;
            flex: 0 0 1px;
            padding: 0;
            background: var(--request-card-border);
        }
    }
}

.request-executors {
    display: flex;

    &__icon {
        display: inline-block;
        margin-left: -28px;
    }

    &__icons {
        display: flex;
        align-items: center;
    }
}
</style>
