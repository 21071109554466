var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination prevent-close"},[_c('div',{staticClass:"pagination__wrapper"},[_c('button',{staticClass:"button button_orange pagination__item pagination__item_action",on:{"click":function($event){return _vm.$emit('prev')}}},[_c('ArrowLeft',{attrs:{"size":22}})],1),_c('button',{staticClass:"button pagination__item pagination__item_control",class:{
                button_green: _vm.page == _vm.first,
                button_orange: _vm.page != _vm.first,
            },on:{"click":function($event){return _vm.$emit('set', _vm.first)}}},[_vm._v(" "+_vm._s(_vm.first)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFirstDots),expression:"isFirstDots"}],staticClass:"pagination__divider"},[_vm._v("...")]),_c('div',{staticClass:"pagination__inner"},[_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLastDots),expression:"isLastDots"}],staticClass:"pagination__divider"},[_vm._v("...")]),_c('button',{staticClass:"button pagination__item pagination__item_control",class:{
                button_green: _vm.page == _vm.pages,
                button_orange: _vm.page != _vm.pages,
            },on:{"click":function($event){return _vm.$emit('set', _vm.pages)}}},[_vm._v(" "+_vm._s(_vm.pages)+" ")]),_c('button',{staticClass:"button button_orange pagination__item pagination__item_action",on:{"click":function($event){return _vm.$emit('next')}}},[_c('ArrowRight',{attrs:{"size":22}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }