<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="resume-date" rules="required" v-slot="{ errors }">
                    <label class="active-form__label active-form__label_required" for="request-pause-resume-date"
                        >Дата возобновления</label
                    >

                    <FlatPickr
                        id="request-pause-resume-date"
                        name="resumeDate"
                        class="active-form__control control"
                        :class="{
                            error: errors[0],
                        }"
                        placeholder="Дата возобновления"
                        required
                        :config="flatpickrConfig"
                        v-model="resumeDate"
                        autofocus
                    ></FlatPickr>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <ValidationProvider class="active-form__field" name="comment" rules="required" v-slot="{ errors }">
                    <label class="active-form__label" for="request-pause-comment">Комментарий</label>

                    <VContenteditable
                        id="request-pause-comment"
                        name="comment"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        @submit="handleSubmit(submit)"
                        v-model="comment"
                    ></VContenteditable>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Приостановить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

@Component({
    components: {
        VButton,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestPauseModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_PAUSE })
    name;
    @Prop({ type: String, default: 'Выберите дату возобновления и причину остановки' })
    defaultTitle;

    comment = '';
    resumeDate = '';

    flatpickrConfig = {
        wrap: true,
        weekNumbers: true,
        dateFormat: 'd.m.Y H:i',
        enableTime: true,
        minDate: 'today',
        maxDate: new Date().fp_incr(1825),
        disable: [
            (date) => {
                return date.getDay() === 0 || date.getDay() === 6;
            },
        ],
        locale: Russian,
        onOpen:[
            (selectedDates, dateStr, instance) => {
                instance.calendarContainer.classList.add('prevent-close');
            },
        ],
    };

    submit() {
        this.resolve({
            comment: this.comment,
            resumeDate: this.resumeDate,
        });

        this.hide();
    }

    beforeClose() {
        this.comment = '';
        this.resumeDate = '';
    }
}

export default VRequestPauseModal;
</script>
