<template>
    <div :class="$style['tools']" class="prevent-close">
        <BaseToolsSearch
            :class="$style['tools__tool']"
            @submit="search"
            placeholder="Поиск"
            v-model="searchString"
        ></BaseToolsSearch>

        <BaseToolsSort
            :class="$style['tools__tool']"
            @sort="sort"
            @toggleFilter="toggleFilter"
            @toggleSettings="$emit('toggleSettings')"
        ></BaseToolsSort>

        <div :class="$style['tools__tool-scroll']">
            <VFilter
                :class="$style['tools__tool']"
                @change="onFilterChange"
                @reset="onFilterChange"
                v-show="toggleFilterStatus"
            ></VFilter>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Watch } from 'vue-property-decorator';

import BaseToolsSearch from './BaseToolsSearch';
import BaseToolsSort from './BaseToolsSort';
import VFilter from '@/views/components/Filter/VFilter';
import VueCustomScrollbar from 'vue-custom-scrollbar';

@Component({
    components: {
        VueCustomScrollbar,
        BaseToolsSearch,
        BaseToolsSort,
        VFilter,
    },
})
class BaseTools extends Vue {
    filter = {
        filter: {},
        search: '',
    };
    searchString = '';
    toggleFilterStatus = false;
    toggleStatisticStatus = false;

    @Emit('sort')
    sort(value) {
        return value;
    }

    @Emit('search')
    search(value) {
        this.filter.search = value;

        return value;
    }

    clearSearchString() {
        this.filter.search = '';
    }

    onFilterChange(value) {
        this.filter.filter = value;

        this.toggleFilterStatus = false;
        this.$emit('change-filter', value);
    }

    toggleFilter() {
        this.toggleFilterStatus = !this.toggleFilterStatus;
        if (this.toggleFilterStatus) this.toggleStatisticStatus = false;
    }

    @Watch('$route.params.method')
    watchRouteParamsMethod() {
        this.toggleStatisticStatus = false;
        this.toggleFilterStatus = false;
    }

    created() {
        this.$bus.on('tools:clear', this.clearSearchString);
    }
    beforeDestroy() {
        this.$bus.off('tools:clear', this.clearSearchString);
    }
}

export default BaseTools;
</script>
<style lang="scss" module>
.tools {
    width: 100%;
    height: 100%;

    &__tool {
        padding-bottom: 10px;
    }

    &__tool-scroll {
    }
}
</style>
