<template>
    <VModalWrapper :title="title" v-on="listeners" v-bind="attributes">
        <div>
            <div class="load" v-if="isLoading">
                <template>
                    <VSpinner></VSpinner>
                </template>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }" v-else>
                <form class="active-form" @submit.prevent="handleSubmit(submit)">
                    <ValidationProvider class="active-form__field" name="category" rules="required" v-slot="{ errors }">
                        <label class="active-form__label active-form__label_required" for="request-category"
                            >Категория</label
                        >

                        <VSelect
                            id="request-category"
                            name="category"
                            label="title"
                            class="active-form__control"
                            placeholder="Выберите категорию"
                            :class="{
                                error: errors[0],
                            }"
                            :options="categories"
                            @input="routeReplace"
                            v-model="category"
                        ></VSelect>

                        <span class="active-form__error">
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>

                    <ValidationProvider class="active-form__field" name="service" rules="required" v-slot="{ errors }">
                        <label class="active-form__label active-form__label_required" for="request-service"
                            >Сервис</label
                        >

                        <VSelect
                            id="request-service"
                            name="service"
                            label="title"
                            class="active-form__control"
                            placeholder="Выберите сервис"
                            :class="{
                                error: errors[0],
                            }"
                            :options="category ? category.services : []"
                            @input="routeReplace"
                            v-model="serviceLocal"
                        ></VSelect>

                        <span class="active-form__error">
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                    <ValidationProvider
                        class="active-form__field"
                        name="description"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <label class="active-form__label active-form__label_required" for="request-description">
                            Описание
                        </label>

                        <VContenteditable
                            v-model="comment"
                            id="request-description"
                            name="description"
                            @input="routeReplace"
                        ></VContenteditable>

                        <span class="active-form__error">
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                    <VRequestObservers v-model="observersLocal" @input="routeReplace"></VRequestObservers>

                    <div class="request-create-view__buttons">
                        <FileUploader class="button button_green" v-model="files">Загрузить</FileUploader>
                    </div>
                    <div class="scroll">
                        <FileViewer upload v-model="files" class="request-create-view__file-viewer"></FileViewer>
                    </div>
                    <div class="active-form__actions">
                        <VButton type="button" class="active-form__action button_green-light" @click="hide">
                            Отмена
                        </VButton>

                        <VButton type="submit" class="active-form__action button_green">Создать обращение</VButton>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';
import { VRequestObservers } from '../components';
import FileViewer from '@/views/components/FileViewer';
import FileUploader from '@/views/components/FileUploader';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VSelect from '../components/VSelect/VSelect.vue';
import VSpinner from '../components/VProgress/VSpinner.vue';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import { debounce } from 'lodash';
import { namespace } from 'vuex-class';
import http from '@/js/http.js';

const Workspace = namespace('workspace');

@Component({
    components: {
        VSelect,
        VButton,
        VModalWrapper,
        VContenteditable,
        VRequestObservers,
        FileViewer,
        FileUploader,
        VSpinner,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestCreateModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_CREATE })
    name;
    @Prop({ type: String, default: 'Создать обращение' })
    defaultTitle;

    @Workspace.State categories;

    isLoading = false;
    files = [];
    comment = null;
    service = null;
    observers = null;
    category = null;

    async submit() {
        this.isLoading = true;
        try {
            const files = this.getFileIds();
            const observersValues = this.observers.map((observer) => observer.value);

            const data = {
                files,
                comment: this.comment,
                observers: observersValues,
                serviceId: this.serviceId,
            };

            const response = await http.post('/requests', data);

            this.resolve(response.data);
        } catch (error) {
            this.reject(error);
        } finally {
            this.isLoading = false;
            this.hide();
        }
    }

    getFileIds() {
        return this.files.filter((file) => file.id && file.id !== -1).map((file) => file.id);
    }

    get categoryId() {
        return parseInt(this.$route.query.categoryId);
    }

    get serviceId() {
        return parseInt(this.$route.query.serviceId);
    }

    get serviceLocal() {
        if (this.service) {
            this.observers =
                this.observers ||
                this.service.observers.map((observer) => ({
                    value: observer.id,
                    label: observer.displayName,
                }));
        }
        return this.service;
    }

    set serviceLocal(value) {
        if (this.service != value) {
            this.service = value;
            this.observers = this.service.observers.map((observer) => ({
                value: observer.id,
                label: observer.displayName,
            }));
        }
    }

    get observersLocal() {
        if (!this.observers) {
            this.observers = this.$route.query.observers ? JSON.parse(this.$route.query.observers) : null;
        }
        return this.observers;
    }

    set observersLocal(value) {
        if (this.observers != value) {
            this.observers = value;
        }
    }

    routeReplace() {
        if (!this.debouncedRouteReplace) {
            this.debouncedRouteReplace = debounce(() => {
                this.$router.replace({
                    query: {
                        categoryId: this.category ? this.category.id : null,
                        serviceId: this.service ? this.service.id : null,
                        comment: this.comment,
                        observers: JSON.stringify(this.observers),
                    },
                });
                this.debouncedRouteReplace = null;
            }, 500);
        }
        this.debouncedRouteReplace();
    }

    beforeOpen() {
        if (this.categoryId && this.categories) {
            this.category = this.categories.find((category) => category.id == this.categoryId) ?? null;
        }

        if (this.serviceId && this.category) {
            this.service = this.category.services.find((service) => service.id == this.serviceId) ?? null;
        }
        this.comment = this.$route.query.comment ?? null;
    }

    beforeClose() {
        this.files = [];
        this.comment = null;
        this.category = null;
        this.service = null;
        this.observers = null;

        const replaceRoute = Object.values(this.$route.query).join('');
        if (replaceRoute) {
            this.$router.replace({
                query: {},
            });
        }
    }
}

export default VRequestCreateModal;
</script>
<style lang="scss">
.load {
    position: relative;
    top: 30%;
    left: 50%;
}
.active-form {
    &__label {
        font-size: 1rem;
        color: #484848;
    }
    &__empty {
        color: red;
    }
    &__control {
        margin-bottom: 10px;
    }
    .scroll {
        overflow-y: auto;
    }
}
.request-create-view {
    &__buttons {
        .button {
            margin: 10px 0;
            width: 100%;
        }
    }
    &__file-viewer {
        max-height: 250px;
    }
}
</style>
