<template>
    <VModalWrapper :title="title" @opened="focus" v-on="listeners" v-bind="attributes">
        <ValidationObserver v-slot="{ handleSubmit }">
            <form class="active-form" @submit.prevent="handleSubmit(submit)">
                <ValidationProvider class="active-form__field" name="solution" rules="required" v-slot="{ errors }">
                    <label class="active-form__label" for="request-solution">Решение</label>

                    <VContenteditable
                        ref="input"
                        id="request-solution"
                        name="solution"
                        class="active-form__control"
                        :class="{
                            error: errors[0],
                        }"
                        :placeholder="'Напишите решение...'"
                        @submit="handleSubmit(submit)"
                        v-model="solution"
                    ></VContenteditable>

                    <span class="active-form__error">
                        {{ errors[0] }}
                    </span>
                </ValidationProvider>

                <div class="active-form__actions">
                    <VButton type="button" class="active-form__action button_green-light" @click="hide">
                        Отмена
                    </VButton>

                    <VButton type="submit" class="active-form__action button_green"> Отправить </VButton>
                </div>
            </form>
        </ValidationObserver>
    </VModalWrapper>
</template>
<script>
// Components
import VButton from '../components/VButton/VButton.vue';
import VModalWrapper from '../components/VModalWrapper/VModalWrapper.vue';
import VContenteditable from '../components/VContenteditable/VContenteditable.vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

// Other
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ModalNames from '../../js/enums/ModalNames';
import ModalMixin from '../../js/vue/mixins/ModalMixin';
import ErrorMessages from '../../js/enums/ErrorMessages';
import { namespace } from 'vuex-class';

const Workspace = namespace('workspace');

@Component({
    components: {
        VButton,
        VModalWrapper,
        VContenteditable,

        ValidationObserver,
        ValidationProvider,
    },
})
class VRequestSolutionModal extends ModalMixin {
    @Prop({ type: String, default: ModalNames.REQUEST_SOLUTION })
    name;
    @Prop({ type: String, default: 'Укажите решение по обращению' })
    defaultTitle;

    @Workspace.State categories;

    request = null;
    errorMessage = null;

    solution = null;

    submit() {
        this.resolve({
            comment: this.solution,
        });
        this.hide();
    }

    focus() {
        const input = this.$refs['input'];

        if (input && input.focus && typeof input.focus === 'function') {
            input.focus();
        }
    }

    beforeOpen(event) {
        if (!event?.params?.request) {
            this.errorMessage = ErrorMessages.SOMETHING_WENT_WRONG;
        }

        this.request = event.params.request;
    }

    beforeClose() {
        this.solution = null;
        this.request = null;
        this.errorMessage = null;
    }
}

export default VRequestSolutionModal;
</script>
<style lang="scss">
.active-form {
    &__label {
        font-size: 1rem;
        color: #484848;
    }
    &__empty {
        color: red;
    }
    &__control {
        margin-bottom: 10px;
    }
}
</style>
