export default [
    [
        {
            active: false,
            title: 'Год',
            name: 'filterYear',
        },
        {
            active: false,
            title: 'Квартал',
            name: 'filterQuarter',
        },
        {
            active: false,
            title: 'Месяц',
            name: 'filterMonth',
        },
        {
            active: false,
            title: 'День',
            name: 'filterDay',
        },
        {
            active: false,
            title: 'Период',
            name: 'filterPeriod',
        },
        {
            active: false,
            title: 'Активность',
            name: 'filterActivity',
        },
    ],
    [
        {
            active: true,
            title: 'Статус',
            name: 'filterStatus',
        },
        {
            active: true,
            title: 'Категория',
            name: 'filterCategory',
        },
        {
            active: true,
            title: 'Сервис',
            name: 'filterService',
        },
        {
            active: false,
            title: 'Город',
            name: 'filterCity',
        },
    ],
    [
        {
            active: false,
            title: 'Автор',
            name: 'filterAuthor',
        },
        {
            active: false,
            title: 'Исполнитель',
            name: 'filterExecutor',
        },
        {
            active: true,
            title: 'Обращения',
            name: 'filterExpired',
        },
        {
            active: true,
            title: 'Группа ответственных',
            name: 'filterSecurityGroup',
        },
    ],
];
